import user from "@/util/user";

export default class Contact {
  constructor(name) {
    this.name = name;
    this.title = "";
    this.otherInfo = "";
    this.deputy1 = 0;
    this.deputy2 = 0;
    this.mobile1 = null;
    this.mobile2 = null;
    this.mobile3 = null;
    this.landline1 = null;
    this.landline2 = null;
    // Possible mobile types:
    // 0 - None/unknown
    // 1 - Work
    // 2 - Private
    this.mobile1Type = 1;
    this.mobile2Type = 1;
    this.mobile3Type = 1;
    this.landline1Type = 1;
    this.landline2Type = 1;
    this.email = "";
    this.source = null;
    this.department = null;
  }

  setId(id) {
    this.id = id;
  }

  setTitle(title) {
    this.title = title;
  }

  setOtherInfo(text) {
    this.otherInfo = text;
  }

  setMobile1(number, type = 1) {
    this.mobile1 = number;
    this.mobile1Type = type;
  }

  setMobile1Type(type) {
    this.mobile1Type = type;
  }

  setMobile2(number, type = 1) {
    this.mobile2 = number;
    this.mobile2Type = type;
  }

  setMobile2Type(type) {
    this.mobile2Type = type;
  }

  setMobile3(number, type = 1) {
    this.mobile3 = number;
    this.mobile3Type = type;
  }

  setMobile3Type(type) {
    this.mobile3Type = type;
  }

  setLandline1(number, type = 1) {
    this.landline1 = number;
    this.landline1Type = type;
  }

  setLandline1Type(type) {
    this.landline1Type = type;
  }

  setLandline2(number, type = 1) {
    this.landline2 = number;
    this.landline2Type = type;
  }

  setLandline2Type(type) {
    this.landline2Type = type;
  }

  setEmail(email) {
    this.email = email;
  }

  setDeputy1(contactId) {
    this.deputy1 = contactId;
  }

  setDeputy2(contactId) {
    this.deputy2 = contactId;
  }

  // Will overwrite defualt department that is logged in users
  // selected department
  setDepartment(department) {
    this.department = department;
  }

  /**
   * @param {ContactSource} contactSource
   */
  setSource(contactSource) {
    this.source = contactSource;
  }

  /**
   * @name getAsApiObject
   *
   * @desc Creates object with a structure that can be used in API
   * communcation.
   * @returns {{Kommentar: string, Mobil: string, Vikar1: number, Vikar2: number, _source: null, Fast: string, Navn, Stilling: string}}
   */
  getAsApiObject() {
    let apiObject = {
      Navn: this.name,
      Stilling: this.title,
      Kommentar: this.otherInfo,
      Mobil: this.mobile1,
      MobilType: this.mobile1Type,
      Mobil2: this.mobile2,
      Mobil2Type: this.mobile2Type,
      Mobil3: this.mobile3,
      Mobil3Type: this.mobile3Type,
      Fast: this.landline1,
      FastType: this.landline1Type,
      Fast2: this.landline2,
      Fast2Type: this.landline2Type,
      Epost: this.email,
      Vikar1: this.deputy1,
      Vikar2: this.deputy2,
      Avdeling:
        this.department === null
          ? user.getSelectedDepartment()
          : this.department,
      _source: null
    };

    if (this.id) {
      apiObject.Id = this.id;
    }
    if (this.source) {
      apiObject._source = this.source.get();
    }

    return apiObject;
  }
}

const createPhoneDataObject = (countryCode, number, type) => {
  return {
    countryCode: countryCode,
    number: number,
    type: type
  };
};
