import excellentExport from "excellentexport";
import stringUtil from "@/util/stringUtil";
import config from "@/config/config";

export default class Export {
  constructor(fileName, data) {
    this.fileName = stringUtil.makeDownloadFriendlyName(fileName);
    this.data = data;
  }

  setHeaders(headers) {
    this.data.unshift(headers);
  }

  exportAsExcel() {
    const options = {
      format: "xlsx",
      filename: this.fileName,
      openAsDownload: true
    };
    // excellentExport not is not so excellent when it comes to sheet names.
    // Throws an error if more than 31 chars. So we truncate it
    const sheetName = stringUtil.truncate(this.fileName, 31);
    const sheets = [
      {
        name: sheetName,
        from: {
          array: this.data
        }
      }
    ];
    excellentExport.convert(options, sheets);
  }

  exportAsCsvFile(separator) {
    if (!separator) {
      separator = config.getLocaleProperties().csvSeparator;
    }
    let csvStr = "";
    let lineIndex = 0;
    const numOfLines = this.data.length;
    for (lineIndex; lineIndex < numOfLines; lineIndex++) {
      const fields = this.data[lineIndex];
      const numOfFields = fields.length;
      let fieldIndex = 0;
      for (fieldIndex; fieldIndex < numOfFields; fieldIndex++) {
        csvStr += createField(fields[fieldIndex], separator);
      }
      // Add line break if not last line
      if (lineIndex < numOfLines - 1) {
        csvStr += addLineBreak();
      }
    }

    exportCsvToFile(csvStr, this.fileName);
  }
}

const addLineBreak = () => {
  return "\r\n";
};

const createField = (data, separator) => {
  data = data + "";
  // Removes line break
  data = data.replace(/\r?\n|\r/g, " ");
  // Escapes quotes
  data = data.replace(/"/g, "'");
  // Removes semicolon-devider
  data = data.replace(/;/g, "");
  return '"' + data + '"' + separator;
};

const exportCsvToFile = (csvStr, fileName) => {
  fileName += ".csv";
  // https://stackoverflow.com/a/16231345/529171
  csvStr = "\uFEFF" + csvStr;
  const pom = document.createElement("a");
  const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8," });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    pom.href = URL.createObjectURL(blob);
    pom.setAttribute("download", fileName);
    document.body.appendChild(pom); // Required for Firefox
    pom.click();
  }
};
