import {v4 as uuidv4} from "uuid";
export default class ContactSource {
  constructor() {
    /**
     * Source name. Indicates where receiver is selected from. Group list
     * Contact list etc.
     * @type {string}
     */
    this.name = "";
    /**
     * ID the id used by the source.
     * @type {number}
     */
    this.id = 0;
    /**
     * When the source is "group" we also need the contact ID so it's is
     * possible to target just the contact and not the entire group
     * @type {number}
     */
    this.contactId = 0;
    /**
     * Available source names
     */
    this.sources = {
      dsf: "dsf",
      group: "group",
      largeGroup: "largeGroup",
      contactList: "contact",
      extraPerson: "extra",
      imported: "import",
      alertLog: "alertLog"

    }
  }

  get() {
    return {
      name: this.getName(),
      id: this.getId(),
      contactId: this.getContactId()
    };
  }
  setDsf(dsfInternalId) {
    this.name = this.sources.dsf;
    this.id = dsfInternalId;
  }

  setGroup(groupId, contactId) {
    this.name = this.sources.group;
    this.id = groupId;
    this.contactId = contactId;
  }

  setLargeGroup(groupId) {
    this.name = this.sources.largeGroup;
    this.id = groupId;
  }

  setContact(contactId) {
    this.name = this.sources.contactList;
    this.id = contactId;
    this.contactId = contactId;
  }

  setExtra(id) {
    this.name = this.sources.extraPerson;
    this.id = id;
  }

  setImport(id) {
    this.name = this.sources.imported;
    this.id = id;
  }

  setAlertLog() {
    this.name = this.sources.alertLog;
    this.id = uuidv4();
  }

  getName() {
    return this.name;
  }

  getId() {
    return this.id;
  }

  getContactId() {
    return this.contactId;
  }
}
