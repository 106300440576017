<template>
  <div>
    <CustomBtn
      :text="text ? text : $t('export')"
      :info="info"
      :primary="primary"
      :outlined="outlined"
      :small="small"
      :icon="icon ? 'mdi-export-variant' : ''"
      v-on:click="onClick"
      :block="block"
    />
    <v-dialog v-model="dialogChooseType" :max-width="dialogMaxWidth">
      <v-card>
        <v-card-title>
          {{ $t("chooseExportFileFormat") }}
        </v-card-title>
        <v-card-text>
          <p>
            <strong>{{ $t("exportFormatQuestion") }}</strong>
          </p>
          <p v-if="anonymizeOption">
            <v-checkbox
              dense
              :label="$t('anonRecData')"
              v-model="anonExportData"
              data-testid="anonymizeExportBtn"
            />
          </p>
          <slot name="extra-info"></slot>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="dialogChooseType = false"
            data-testid="cancelExportBtn"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="selectFormat(false)"
            class="primary"
            data-testid="csvExportBtn"
          >
            CSV
          </v-btn>
          <v-btn
            @click="selectFormat(true)"
            class="primary"
            data-testid="excelExportBtn"
          >
            Excel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomBtn from "@/components/misc/CustomBtn";
import config from "@/config/config";

export default {
  name: "ExportBtn",
  components: { CustomBtn },

  props: {
    info: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: ""
    },
    icon: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    anonymizeOption: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dialogChooseType: false,
    dialogMaxWidth: config.dialogWith.medium,
    anonExportData: false
  }),

  methods: {
    onClick() {
      this.dialogChooseType = true;
    },
    selectFormat(useExcel) {
      this.dialogChooseType = false;
      this.$emit("click", {
        useExcel: useExcel,
        anon: this.anonExportData
      });
    }
  }
};
</script>
